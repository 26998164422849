/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "GCPのリソース管理をterraformでやるために、まずは既存のものをimportしてみた"), "\n", React.createElement(_components.p, null, "importまで案外大変だったので書き残しておく"), "\n", React.createElement(_components.h3, {
    id: "環境",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E7%92%B0%E5%A2%83",
    "aria-label": "環境 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "環境"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "ubuntu: 18.04"), "\n", React.createElement(_components.li, null, "terraformer: 0.8.10"), "\n", React.createElement(_components.li, null, "tfenv: 2.0.0"), "\n", React.createElement(_components.li, null, "terraform: 0.14.5"), "\n"), "\n", React.createElement(_components.h3, {
    id: "セットアップ",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%82%BB%E3%83%83%E3%83%88%E3%82%A2%E3%83%83%E3%83%97",
    "aria-label": "セットアップ permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "セットアップ"), "\n", React.createElement(_components.h4, {
    id: "terraform-with-tfenv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#terraform-with-tfenv",
    "aria-label": "terraform with tfenv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "terraform with tfenv"), "\n", React.createElement(_components.p, null, "おおよそtfenvのREADME通り。terraformのバージョンは最新かつalpha版でないものをつかう"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">git</span> clone https://github.com/tfutils/tfenv.git ~/.tfenv\n<span class=\"token string\">'export PATH=\"$HOME/.tfenv/bin:$PATH\"'</span> <span class=\"token operator\">>></span> ~/.bash_profile\n<span class=\"token builtin class-name\">source</span> ~/.bash_profile \n\ntfenv list-remote\n<span class=\"token punctuation\">..</span>.</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">tfenv <span class=\"token function\">install</span> <span class=\"token number\">0.14</span>.5\ntfenv use <span class=\"token number\">0.14</span>.5</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "確認"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">tfenv list\nterraform version</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h4, {
    id: "terraformer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#terraformer",
    "aria-label": "terraformer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "terraformer"), "\n", React.createElement(_components.p, null, "こちらもREADME通り"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">git</span> clone git@github.com:GoogleCloudPlatform/terraformer.git</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "使用するproviderはgoogle"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token builtin class-name\">export</span> <span class=\"token assign-left variable\">PROVIDER</span><span class=\"token operator\">=</span><span class=\"token punctuation\">{</span>google<span class=\"token punctuation\">}</span>\n<span class=\"token function\">curl</span> <span class=\"token parameter variable\">-LO</span> https://github.com/GoogleCloudPlatform/terraformer/releases/download/<span class=\"token variable\"><span class=\"token variable\">$(</span><span class=\"token function\">curl</span> <span class=\"token parameter variable\">-s</span> https://api.github.com/repos/GoogleCloudPlatform/terraformer/releases/latest <span class=\"token operator\">|</span> <span class=\"token function\">grep</span> tag_name <span class=\"token operator\">|</span> <span class=\"token function\">cut</span> <span class=\"token parameter variable\">-d</span> <span class=\"token string\">'\"'</span> <span class=\"token parameter variable\">-f</span> <span class=\"token number\">4</span><span class=\"token variable\">)</span></span>/terraformer-<span class=\"token variable\">${PROVIDER}</span>-linux-amd64\n<span class=\"token function\">chmod</span> +x terraformer-<span class=\"token variable\">${PROVIDER}</span>-linux-amd64\n<span class=\"token function\">sudo</span> <span class=\"token function\">mv</span> terraformer-<span class=\"token variable\">${PROVIDER}</span>-linux-amd64 /usr/local/bin/terraformer</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "確認"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">terraformer version</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h3, {
    id: "gcpのリソースimport",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#gcp%E3%81%AE%E3%83%AA%E3%82%BD%E3%83%BC%E3%82%B9import",
    "aria-label": "gcpのリソースimport permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "GCPのリソースimport"), "\n", React.createElement(_components.p, null, "terraformerでimportする。terraformでproviderの設定をする必要がある"), "\n", React.createElement(_components.h4, {
    id: "terraform",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#terraform",
    "aria-label": "terraform permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "terraform"), "\n", React.createElement(_components.p, null, "ほしいリソースについての、providerを作成"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "\n        <div class=\"undefined\">\n          <span>versions.tf</span>\n        </div>\n       "
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"hcl\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-hcl line-numbers\"><code class=\"language-hcl\"><span class=\"token keyword\">terraform</span> <span class=\"token punctuation\">{</span>\n  <span class=\"token keyword\">required_providers</span> <span class=\"token punctuation\">{</span>\n    <span class=\"token property\">google</span> <span class=\"token punctuation\">=</span> <span class=\"token punctuation\">{</span>\n      <span class=\"token property\">source</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"hashicorp/google\"</span>\n    <span class=\"token punctuation\">}</span>\n  <span class=\"token punctuation\">}</span>\n  <span class=\"token property\">required_version</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\">= 0.14\"</span>\n<span class=\"token punctuation\">}</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "を作成し"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">terraform init</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "カレントディレクトリにprovidersが作成されるので、terraformerのpluginsディレクトリへコピー"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">rsync</span> <span class=\"token parameter variable\">-av</span> .terraform/providers/registry.terraform.io/hashicorp/google/3.54.0/linux_amd64 ~/~.terraform.d/plugins</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h4, {
    id: "terraformer-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#terraformer-1",
    "aria-label": "terraformer 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "terraformer"), "\n", React.createElement(_components.p, null, "認証情報はサービスアカウントを作成して、.jsonキーファイルを作成し、ダウンロードしておく（名前は", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">terraform.json</code>"
    }
  }), "とした）"), "\n", React.createElement(_components.p, null, "versions.tfに認証情報をかいても読み取ってくれないようで、使用するキーファイルのパスを環境変数で設定する必要がある"), "\n", React.createElement(_components.p, null, "コマンドにてimportするproject region resourceの指定をする"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token builtin class-name\">export</span> <span class=\"token assign-left variable\">GOOGLE_APPLICATION_CREDENTIALS</span><span class=\"token operator\">=</span><span class=\"token string\">\"terraform.json\"</span>\nterraformer <span class=\"token function\">import</span> google <span class=\"token parameter variable\">--resources</span><span class=\"token operator\">=</span>instances,forwardingRules,httpHealthChecks <span class=\"token parameter variable\">--regions</span><span class=\"token operator\">=</span>us-west1 <span class=\"token parameter variable\">--projects</span><span class=\"token operator\">=</span>xxxx-12345\n\n<span class=\"token punctuation\">..</span>. \n<span class=\"token number\">2021</span>/01/11 00:53:33 google importing project xxxx- region us-west1</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "importする対象を指定するresourcesの値は"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">terraformer <span class=\"token function\">import</span> google list <span class=\"token parameter variable\">--projects</span><span class=\"token operator\">=</span>xxxx-12345</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "で確認できる"), "\n", React.createElement(_components.p, null, "importされたtfファイル群は", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">generated/</code>"
    }
  }), "以下に保存される"), "\n", React.createElement(_components.h3, {
    id: "terraform-init",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#terraform-init",
    "aria-label": "terraform init permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "terraform init"), "\n", React.createElement(_components.p, null, "リソースごとにディレクトリが切られるので、instanceだけ試した"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">terraform init\n<span class=\"token punctuation\">..</span>.\nError: Invalid legacy provider address\n<span class=\"token punctuation\">..</span>.</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "terraformerで作られるtfファイル群は対応するバージョンが低い様子"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://stackoverflow.com/questions/65396812/receive-error-invalid-legacy-provider-address-with-terraform-in-bitbucket-pipe"
  }, "Receive error ‘Invalid legacy provider address’ with Terraform in Bitbucket pipeline - Stack Overflow"), "\nを参考に以下コマンドを実行"), "\n", React.createElement(_components.p, null, "変更確認がでるので", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">yes</code>"
    }
  }), "で更新する"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">terraform state replace-provider <span class=\"token string\">\"registry.terraform.io/-/google\"</span> <span class=\"token string\">\"hashicorp/google\"</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">terraform init\nterraform plan\n<span class=\"token punctuation\">..</span>.\nPlan: <span class=\"token number\">0</span> to add, <span class=\"token number\">0</span> to change, <span class=\"token number\">0</span> to destroy.</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "差分もなさそうなので、良い感じ"), "\n", React.createElement(_components.h3, {
    id: "参考",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%8F%82%E8%80%83",
    "aria-label": "参考 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "参考"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://registry.terraform.io/providers/hashicorp/google/latest/docs"
  }, "terrafoem proveders google")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://zoo200.net/export-aws-with-terraformer/"
  }, "Terraformerを使用して既存のAWS環境をエクスポートする | zoo200’s MemoMemo")), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
